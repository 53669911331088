import { useEffect, useMemo, useState } from "react";
import SnackBar from "./Components/SnackBar/SnackBar";
import RaiseComplaint from "./Screens/RaiseComplaint/RaiseComplaint";
import { useAuthSubscriber } from "./Services/authentication";
import LogRocket from "logrocket";
import { logRocketInit } from "./utils/constants";
import { LanguageProvider } from "./Components/LanguageContext/LanguageContext";
import { getLocations } from "./Services/database";
import { search } from "fast-fuzzy";

function App(props) {
  const [status, setStatus] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [locations, setLocations] = useState(() => {
    const data = localStorage.getItem("locations");
    return data ? JSON.parse(data) : [];
  });

  useAuthSubscriber(
    (isAuth) => {
      setIsAuth(isAuth);
    },
    (onAccessToken) => {
      setAccessToken(onAccessToken);
    }
  );

  const fetchDataAndStore = async () => {
    try {
      const data = await getLocations();
      setLocations(data);
      localStorage.setItem("locations", JSON.stringify(data));
      localStorage.setItem("timestamp", +new Date());
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  useEffect(() => {
    const timestamp = localStorage.getItem("timestamp");
    if (!timestamp || (timestamp && +new Date() - timestamp > 300000)) {
      console.log("called fetch");
      fetchDataAndStore();
    }
  }, []);

  useEffect(() => {
    setStatus(null);
  }, [status]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      if (logRocketInit.key) {
        LogRocket.init(logRocketInit.key);
      }
    }
  }, []);

  const results = useMemo(() => {
    if (!searchKey) {
      return locations;
    }
    return search(searchKey, locations, {
      keySelector: (item) => `${item.location} ${item.PCTNo}`,
      threshold: 0.7,
    });
  }, [searchKey, locations]);

  return (
    <LanguageProvider>
      <div className="inherit-parent-height display-flex flex-direction-column flex-justify-content-center flex-align-items-center">
        <RaiseComplaint
          locations={results}
          isAuth={isAuth}
          setStatus={setStatus}
          setSearchKey={setSearchKey}
          searchKey={searchKey}
          accessToken={accessToken}
        />
      </div>
      <SnackBar
        message={status?.message}
        status={status}
        type={status?.code === null ? "success" : "error"}
      />
    </LanguageProvider>
  );
}

export default App;
